import classes from './styles.module.scss'
import AppTypography from 'src/components/elements/typography/AppTypography'
import AppIcon from 'src/components/elements/icons/AppIcon'
import clsx from 'clsx'

export default function WarningMessage(props: {
  message?: string | null | React.ReactNode
  actionLabel?: string
  actionOnClick?: () => void
  small?: boolean
  noMargin?: boolean
}) {
  const {small = false, noMargin = false} = props

  if (!props.message) {
    return null
  }

  return (
    <div
      className={clsx(
        classes.warningContainer,
        small ? classes.small : '',
        noMargin ? classes.noMargin : '',
      )}
    >
      <AppIcon
        name="warning"
        className={small ? classes.smallIcon : classes.icon}
      />
      <AppTypography
        variant={small ? 'caption' : 'action'}
        className={classes.warningText}
      >
        {props.message}
        {props.actionLabel && (
          <>
            <br />
            <span
              onClick={() =>
                props.actionOnClick ? props.actionOnClick() : undefined
              }
              className={classes.action}
            >
              {props.actionLabel}
            </span>
          </>
        )}
      </AppTypography>
    </div>
  )
}
