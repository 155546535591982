import {dayjs, Dayjs} from 'src/utils/date'
import {
  BasicPricingFormValues,
  CalendarValues,
  CustomPricingValues,
} from 'src/types/form'
import {useCallback, useState} from 'react'
import {PropertyType} from 'src/types/property'

export function getCalendarsForDate(
  date: Dayjs,
  calendars?: CalendarValues[] | null,
): CalendarValues[] {
  if (!calendars) {
    return []
  }

  const filteredCalendars = calendars.filter((calendar: CalendarValues) => {
    return (
      date.isBetween(calendar.start, calendar.end) ||
      date.isSame(calendar.start, 'hour') ||
      date.isSame(calendar.end, 'hour')
    )
  })

  let automaticPeriods = filteredCalendars.filter((p) => p.is_automatic_period)
  let notAutomaticPeriods = filteredCalendars.filter(
    (p) => !p.is_automatic_period,
  )

  const sortBy = [null, '', 'season', 'events'] //events should be last and have priority
  automaticPeriods = automaticPeriods.sort((a, b) => {
    return sortBy.indexOf(a.automatic_period_type) >
      sortBy.indexOf(b.automatic_period_type)
      ? 1
      : -1
  })

  //not automatic is always more important so put in last position since last position will have priority
  return [...automaticPeriods, ...notAutomaticPeriods]
}

export function getMinNightsToRentForDate(
  date: Dayjs,
  property: PropertyType,
  notBlockPeriodCalendars: CalendarValues[],
) {
  let minNightsToRent = property.rental_parameter.min_nights_to_rent

  for (let calendar of notBlockPeriodCalendars) {
    if (!calendar.custom_pricing?.rental_parameter) {
      continue
    }
    if (!calendar.custom_pricing?.rental_parameter?.min_nights_to_rent) {
      continue
    }
    const calendarStart = dayjs(calendar.start)
    const calendarEnd = dayjs(calendar.end)

    //reverse logic the following if
    if (
      !date.isBetween(calendarStart, calendarEnd) &&
      !date.isSame(calendarStart, 'hour') &&
      !date.isSame(calendarEnd, 'hour')
    ) {
      continue
    }

    let customPricingMinNightsToRent =
      calendar.custom_pricing?.rental_parameter?.min_nights_to_rent

    if (customPricingMinNightsToRent > minNightsToRent) {
      minNightsToRent = customPricingMinNightsToRent
    }
  }

  return minNightsToRent
}

export function getPrice(
  isWeekEnd: boolean,
  priceInfo: BasicPricingFormValues | CustomPricingValues | null,
) {
  if (isWeekEnd && priceInfo?.weekend_rate) {
    return priceInfo?.weekend_rate
  }
  if (isWeekEnd && !priceInfo?.weekend_rate) {
    return priceInfo?.base_rate
  }

  return priceInfo?.base_rate
}

const currentMonth = dayjs().format('YYYY-MM')
const currentNextMonth = dayjs().add(1, 'month').format('YYYY-MM')

export function useVisibleMonths() {
  const [visibleMonths, setVisibleMonths] = useState<string[]>([
    currentMonth,
    currentNextMonth,
  ])

  const nextMonth = useCallback(() => {
    let currentMonthDayJs = dayjs(visibleMonths[0], 'YYYY-MM')
    setVisibleMonths([
      currentMonthDayJs.add(1, 'month').format('YYYY-MM'),
      currentMonthDayJs.add(2, 'month').format('YYYY-MM'),
    ])
  }, [visibleMonths])

  const previousMonth = useCallback(() => {
    let currentMonthDayJs = dayjs(visibleMonths[0], 'YYYY-MM')
    setVisibleMonths([
      currentMonthDayJs.subtract(1, 'month').format('YYYY-MM'),
      currentMonthDayJs.format('YYYY-MM'),
    ])
  }, [visibleMonths])

  return {
    visibleMonths,
    nextMonth,
    previousMonth,
    setVisibleMonths,
  }
}
