import removeAccents from 'remove-accents'
import {BreadcrumbType} from 'src/types/breadcrumb'

function formatBreadcrumb(breadcrumb: string) {
  if (!breadcrumb) {
    return ''
  }

  return breadcrumb.replace(/[-]+/g, '-')
}

export function formatUrl(url: string) {
  if (!url) {
    return ''
  }

  // remove quote, comma, replace double hyphen to single hyphen
  return removeAccents(url.toLowerCase().replace(/ /g, '-'))
    .replace(',', '')
    .replace("'", '')
    .replace(/[-]+/g, '-')
}

const urlMappings = new Map([
  ['/capitale-nationale/charlevoix-est', '/charlevoix'],
  ['/capitale-nationale/charlevoix', '/charlevoix'],
  [
    '/capitale-nationale/petite-riviere-saint-francois',
    '/charlevoix/petite-riviere-saint-francois',
  ],
  [
    '/capitale-nationale/stoneham-et-tewkesbury',
    '/capitale-nationale/stoneham',
  ],
  ['/capitale-nationale/baie-saint-paul', '/charlevoix/baie-saint-paul'],
  ['/capitale-nationale/st-raymond', '/capitale-nationale/saint-raymond'],
  ['/capitale-nationale/la-malbaie', '/charlevoix/la-malbaie'],
  ['/saguenay-lac-saint-jean', '/saguenay'],
  ['/laurentides/saint-adolphe-dhoward', '/laurentides/saint-adolphe'],
  ['/estrie/mansonville-quebec', '/estrie/mansonville'],
  ['/lanaudiere/saint-donat-de-montcalm', '/lanaudiere/saint-donat'],
  ['/lanse-saint-jean', '/anse-saint-jean'],
  ['/saint-faustin-lac-carre', '/saint-faustin-du-lac-carre'],
  ['/la-jacques-cartier', '/jacques-cartier'],
  ['/les-laurentides', '/laurentides'],
])
//const urlMappingsKeys = Array.from(urlMappings.keys())

export function updateExceptionalStringsInUrl(url: string) {
  if (!url) {
    return ''
  }
  let newUrl = url
  for (const [key, value] of urlMappings) {
    newUrl = newUrl.replace(key, value)
  }

  return newUrl
}

export function filterBreadcrumbs(breadcrumbs: BreadcrumbType[]) {
  const result = []

  let index = -1
  const breadcrumbLength = breadcrumbs.length
  for (const breadcrumb of breadcrumbs) {
    index++
    const {text: currentBreadcrumbText, url} = breadcrumb

    if (currentBreadcrumbText === 'Saint-Faustin-Lac-Carré') {
      result.push({
        ...breadcrumb,
        text: 'Saint-Faustin-du-Lac-Carré',
      })
      continue
    }

    if (currentBreadcrumbText === 'Stoneham-et-Tewkesbury') {
      result.push({
        ...breadcrumb,
        text: 'Stoneham',
      })
      continue
    }

    if (currentBreadcrumbText === 'La Jacques-Cartier') {
      result.push({
        ...breadcrumb,
        text: 'Jacques-Cartier',
      })
      continue
    }

    if (currentBreadcrumbText === "Saint-Adolphe-d'Howard") {
      result.push({
        ...breadcrumb,
        text: 'Saint-Adolphe',
      })
      continue
    }

    if (currentBreadcrumbText === 'Les Laurentides') {
      result.push({
        ...breadcrumb,
        text: 'Laurentides',
      })
      continue
    }

    if (currentBreadcrumbText === 'Mansonville, Quebec') {
      result.push({
        ...breadcrumb,
        text: 'Mansonville',
      })
      continue
    }

    if (currentBreadcrumbText === 'Saint-Donat-de-Montcalm') {
      result.push({
        ...breadcrumb,
        text: 'Saint-Donat',
      })
      continue
    }

    if (currentBreadcrumbText === 'St-Raymond') {
      result.push({
        ...breadcrumb,
        text: 'Saint-Raymond',
      })
      continue
    }

    if (
      currentBreadcrumbText === 'Capitale-Nationale' &&
      index < breadcrumbLength - 1
    ) {
      const nextBreadcrumb = breadcrumbs[index + 1]
      const {text: nextBreadcrumbText} = nextBreadcrumb
      if (
        nextBreadcrumbText === 'Charlevoix' ||
        nextBreadcrumbText === 'Charlevoix-Est'
      ) {
        continue
      }

      if (
        nextBreadcrumbText === 'Petite-Rivière-Saint-François' ||
        nextBreadcrumbText === 'La Malbaie' ||
        nextBreadcrumbText === 'Baie-Saint-Paul'
      ) {
        result.push({
          url: url.replace('/capitale-nationale', '/charlevoix'),
          text: 'Charlevoix',
        })
        continue
      }
    }

    if (
      (currentBreadcrumbText === 'Charlevoix' ||
        currentBreadcrumbText === 'Charlevoix-Est') &&
      index > 2
    ) {
      const prevBreadcrumb = breadcrumbs[index - 1]
      const {text: prevBreadcrumbText} = prevBreadcrumb
      if (prevBreadcrumbText === 'Capitale-Nationale') {
        const replaceUrl =
          currentBreadcrumbText === 'Charlevoix'
            ? '/charlevoix'
            : '/charlevoix-est'
        result.push({
          url: url.replace(replaceUrl, '/charlevoix'),
          text: 'Charlevoix',
        })
        continue
      }
    }

    result.push({
      ...breadcrumb,
      text: formatBreadcrumb(breadcrumb.text),
    })
  }

  return result
}
