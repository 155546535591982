import {dayjs, Dayjs} from 'src/utils/date'
import {SearchPropertiesParamsType} from 'src/types/search'
import store from 'src/store'
import {
  BASE_URL,
  PROPERTY_PAGE_CHALETS_A_LOUER,
  PROPERTY_PAGE_COTTAGE_RENTAL,
} from 'src/constants/route'
import {DEFAULT_LOCALE, LOCALE_EN} from 'src/constants/locale'
import {internetTypeOptions} from 'src/constants/property'
import {PropertyBreadcrumbType, PropertyType} from 'src/types/property'
import {PropertyAddressType} from 'src/types/address'
import {formatUrl, updateExceptionalStringsInUrl} from './breadcrumb'
import {LocaleType} from 'src/types/utils'
import {PropertyPhotoType} from 'src/types/property'
import {APP_SLUG, SlugType} from 'src/constants/slug'
import qs from 'qs'

type QueryType = {
  s_date: string
  e_date: string
  adults: number
  children: number
  infants: number
  animals: number
}

export function getUniquePropertyUrl(
  locale: string,
  property: {
    addresses: PropertyAddressType[]
    breadcrumbs: PropertyBreadcrumbType[]
    handle: string
  },
  withBase: boolean = true,
  query: Partial<QueryType> = {},
) {
  const address =
    property.addresses.find((a: PropertyAddressType) => a.locale === locale) ||
    null

  let route = '/'
  if (withBase) {
    route = `${BASE_URL}/`
    let name = PROPERTY_PAGE_COTTAGE_RENTAL
    if (locale === DEFAULT_LOCALE) {
      name = PROPERTY_PAGE_CHALETS_A_LOUER
      route = `${route}${name}/`
    }

    if (locale !== DEFAULT_LOCALE) {
      route = `${route}${locale}/${name}/`
    }
  }

  let queryStr: string = ''
  if (query) {
    const finalParams: any = {}

    if (query.s_date && query.e_date) {
      finalParams.start = query.s_date
      finalParams.end = query.e_date
    }

    if (query.adults) {
      finalParams.adults = query.adults
    }
    if (query.children) {
      finalParams.children = query.children
    }
    if (query.infants) {
      finalParams.infants = query.infants
    }
    if (query.animals) {
      finalParams.animals = query.animals
    }
    if (Object.keys(finalParams).length > 0) {
      queryStr = qs.stringify(finalParams)
    }
  }

  const breadcrumbs = property.breadcrumbs

  if (breadcrumbs.length) {
    for (const breadcrumb of breadcrumbs) {
      route = `${route}${formatUrl(breadcrumb.title)}/`
    }

    const breadcrumbUrl = updateExceptionalStringsInUrl(
      `${route}${property.handle}`,
    )
    return `${breadcrumbUrl}${queryStr ? `?${queryStr}` : ''}`
  }

  if (!address) {
    let addressUrl = updateExceptionalStringsInUrl(`${route}${property.handle}`)
    return `${addressUrl}${queryStr ? `?${queryStr}` : ''}`
  }

  if (address.administrative_area) {
    const newRegion = formatUrl(address.administrative_area)
    route = `${route}${newRegion}/`
  }

  if (address.city) {
    const newCity = formatUrl(address.city)
    route = `${route}${newCity}/`
  }

  if (!address.city && address.mrc) {
    const newMrc = formatUrl(address.mrc)
    route = `${route}${newMrc}/`
  }

  const url = updateExceptionalStringsInUrl(`${route}${property.handle}`)
  return `${url}${queryStr ? `?${queryStr}` : ''}`
}

export function formatDate(
  date: Date | Dayjs | string | number,
  format: string,
) {
  if (dayjs.isDayjs(date)) {
    return dayjs(date).format(format)
  }
  return dayjs(date, format).format(format)
}

export function getSearchParams(page: number): SearchPropertiesParamsType {
  const state = store.getState()

  const dateRange = state.propertySearch.selectedDateRange
  const guests = state.propertySearch.selectedGuests
  const amenities: string[] = state.propertySearch.selectedAmenities

  //Filters
  const priceRange = state.propertySearch.priceRangeFilter
  const moreFilters = state.propertySearch.moreFilters
  const sortByFilter = state.propertySearch.sortByFilter
  const mapBounds = state.propertySearch.mapBounds
  const mapCenterBounds = state.propertySearch.mapCenterBounds
  const selectedLocation = state.propertySearch.selectedLocation
  const zoom = state.propertySearch.zoom

  const formattedStartDate =
    dateRange?.startDate && formatDate(dateRange.startDate, 'YYYY-MM-DD')
  const formattedEndDate =
    dateRange?.endDate && formatDate(dateRange.endDate, 'YYYY-MM-DD')

  //todo reduce lat and lng precision so cache work more often
  const params: SearchPropertiesParamsType = {
    ...(dateRange?.startDate &&
      formattedStartDate !== null && {start_date: formattedStartDate}),
    ...(dateRange?.endDate &&
      formattedEndDate !== null && {end_date: formattedEndDate}),
    ...(guests?.adults && guests?.adults !== 0 && {adults: guests?.adults}),
    ...(guests?.children &&
      guests?.children !== 0 && {children: guests?.children}),
    ...(guests?.infants && guests?.infants !== 0 && {infants: guests?.infants}),
    ...(guests?.animals && guests?.animals !== 0 && {animals: guests?.animals}),
    ...(moreFilters?.beds &&
      moreFilters?.beds !== 0 && {number_of_beds: moreFilters?.beds}),
    ...(moreFilters?.capacity &&
      moreFilters?.capacity !== 0 && {maximum_guests: moreFilters?.capacity}),
    ...(moreFilters?.toilets &&
      moreFilters?.toilets !== 0 && {
        number_of_rooms_bath: moreFilters?.toilets,
      }),
    ...(moreFilters?.rooms &&
      moreFilters?.rooms !== 0 && {number_of_rooms: moreFilters?.rooms}),
    ...(priceRange?.minPrice &&
      priceRange?.minPrice !== 0 && {min_price: priceRange?.minPrice}),
    ...(priceRange?.maxPrice &&
      priceRange?.maxPrice !== 0 && {max_price: priceRange?.maxPrice}),
    ...(sortByFilter && sortByFilter !== '' ? {order_by: sortByFilter} : {}),
    ...(amenities?.length && amenities?.length > 0 && {amenities: amenities}),
    internet_type: moreFilters.internetType,
    bounds: mapBounds,
    center_bounds: selectedLocation?.coordinates ?? mapCenterBounds,
    photos_limit: 5,
    zoom,
    page,
    address: selectedLocation?.address ?? '',
  }

  return params
}

export const getInternetTypeOptionByValue = (value: string) => {
  return internetTypeOptions.find((option: any) => option.value === value)
}

export const getInternetTypeInfo = (
  info: string,
  currentLocale: LocaleType,
) => {
  if (info === 'No internet connection') {
    return ''
  }

  const option = getInternetTypeOptionByValue(info)
  if (option) {
    return option.content[currentLocale]
  }

  return ''
}

export const getInternetTypeInfoOther = (
  info: string,
  currentLocale: LocaleType,
  t: any,
) => {
  if (info === 'No internet connection') {
    return t(info, {ns: 'search'})
  }
  const option = getInternetTypeOptionByValue(info)
  if (option) {
    return `${t(info, {ns: 'search'})} (${option.content[currentLocale]})`
  }

  return t(info, {ns: 'search'})
}

export const useBedCountError = (rooms: any) => {
  const bedCounts = rooms.map((room: any) => {
    if (room.sleeping_types) {
      const bedCount = Object.values(room.sleeping_types).reduce(
        (acc: any, curr: any) => {
          if (Number.isFinite(curr)) {
            return acc + curr
          } else {
            return acc
          }
        },
        0,
      )

      return bedCount
    }

    return 0
  })

  return bedCounts.every((count: number) => count)
}

export function propertyToFormValues(
  property: PropertyType,
  setValue: Function,
  currentLocale: string,
) {
  for (let key in property) {
    setValue(key, property[key as keyof PropertyType])
  }
  const address = property.addresses.find(
    (a: PropertyAddressType) => a.locale === currentLocale,
  )

  if (address) {
    setValue('mrc', address.mrc)
    setValue('mrc_other', address.mrc_other)
    setValue('administrative_area', address.administrative_area)
    setValue('city', address.city)
    setValue('country', address.country)
    setValue('state', address.state)
    setValue('county', address.county)
    setValue('full_address', address.full_address)
    setValue('postal_code', address.postal_code)
  }
}

export function getPropertyPhotos(
  property:
    | Pick<PropertyType, 'use_same_photos_slug' | 'photos' | 'slugs'>
    | undefined
    | null,
) {
  if (!property) {
    return []
  }

  let slug = APP_SLUG
  if (property.use_same_photos_slug && property.slugs.length > 1) {
    slug = property.use_same_photos_slug
  }

  return filterPhotosBySlug(property.photos, slug)
}

export function filterPhotosBySlug(
  photos: PropertyPhotoType[],
  slug: SlugType,
) {
  return photos.filter((photo) => photo.slug === slug)
}

export const getPropertyPhotoAltText = (
  photo: PropertyPhotoType,
  currentLocale: LocaleType,
) => {
  let altText = photo.alt_text_fr ? photo.alt_text_fr : ''
  if (currentLocale === LOCALE_EN) {
    altText = photo.alt_text_en ? photo.alt_text_en : altText
  }

  let baseAlt = altText
    ? currentLocale === LOCALE_EN
      ? [['Cottage', 'Nature,', 'Québec', 'Canada'][photo.order % 4]]
      : [['Chalet', 'Nature', 'Québec', 'Canada'][photo.order % 4]]
    : []

  return `${baseAlt.join(', ')}${altText ? `, ${altText}` : ''}`
}
