import {ICALS} from 'src/constants/ical'
import {IcalFeedValues} from 'src/types/form'
import {IcalType} from 'src/types/ical'
import {Dayjs, dayjs} from 'src/utils/date'
import * as Sentry from '@sentry/nextjs'

export const getIcalColor = (type: string, index: number) => {
  const colors = ['#E2F4EE', '#FBEDDA', '#DCE8F9', '#E4DCF9']

  const find = ICALS.find((ical) => ical.name === type)
  if (find) {
    return find.color
  }

  return colors[index % 4]
}

export const isDefaultIcal = (type: string) => {
  const find = ICALS.find((ical) => ical.name === type)
  if (find) {
    return true
  }

  return false
}

export const extractIcalFeedsData = (
  icalFeeds?: IcalFeedValues[],
): {icals: IcalType[]; icalsMap: {[key: string]: number[]}} => {
  let finalIcals: IcalType[] = []
  let icalsMap: {[key: string]: number[]} = {}

  if (!icalFeeds) {
    return {
      icals: finalIcals,
      icalsMap,
    }
  }

  let indexForColor = 0
  let index = 0
  for (const icalFeed of icalFeeds) {
    if (!icalFeed.events) {
      continue
    }

    try {
      const color = getIcalColor(icalFeed.type, indexForColor)

      for (const event of icalFeed.events) {
        const ical: IcalType = {
          id: icalFeed.id,
          name: icalFeed.name,
          type: icalFeed.type,
          start: event.start, //todo:Alex convert to dayjs ?
          end: event.end, //todo:Alex convert to dayjs?
          color,
          summary: event.summary || '',
          description: event.description || '',
        }

        let eventEndDayJs = dayjs(event.end)
        finalIcals.push(ical)

        //Map stuff
        let inBetweenDay = dayjs(event.start)

        while (inBetweenDay.isSameOrBefore(eventEndDayJs, 'day')) {
          let formatted = inBetweenDay.format('YYYY-MM-DD')
          if (typeof icalsMap[formatted] === 'undefined') {
            icalsMap[formatted] = []
          }
          icalsMap[formatted].push(index)
          inBetweenDay = inBetweenDay.add(1, 'days')
        }

        index++
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      Sentry.captureException(e)
    }

    if (!isDefaultIcal(icalFeed.type)) {
      indexForColor++
    }
  }

  return {
    icals: finalIcals,
    icalsMap,
  }
}

export const getFilteredIcals = (
  date: string | Dayjs,
  icals: IcalType[],
  icalsMap: {[key: string]: number[]},
) => {
  const formatted = dayjs(date).format('YYYY-MM-DD')
  let filteredIcals: IcalType[] = []
  if (typeof icalsMap[formatted] !== 'undefined') {
    filteredIcals = icalsMap[formatted].map((idx) => icals[idx])
  }
  return filteredIcals
}
