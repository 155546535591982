import {dayjs, Dayjs} from 'src/utils/date'
import {
  BasicPricingFormValues,
  BookingPeriodValues,
  CalendarValues,
  DatesStatusMap,
  CustomPricingValues,
  RentalParametersFormValues,
} from 'src/types/form'
import {IcalType} from 'src/types/ical'
import {getFilteredIcals} from 'src/utils/ical'
import {getCurrencySymbol} from 'src/utils/other'
import {PropertyType} from 'src/types/property'
import {getCalendarsForDate, getPrice} from 'src/utils/calendar'

export type VariantType = 'start' | 'end' | 'middle'
export type DateStatusNumber = -1 | 0 | 1 | 2 | 3

export const DATE_STATUS_PAST: DateStatusNumber = -1
export const DATE_STATUS_DISABLED: DateStatusNumber = 0
export const DATE_STATUS_ENABLED: DateStatusNumber = 1
export const DATE_STATUS_ENABLED_AM: DateStatusNumber = 2
export const DATE_STATUS_ENABLED_PM: DateStatusNumber = 3

const now = dayjs()

export const getDateStatus = (
  date: Dayjs,
  blockPeriodCalendars?: CalendarValues[],
  icals?: IcalType[],
  icalsMap?: {[key: string]: number[]},
  bookingPeriods?: BookingPeriodValues[],
  rentalParameter?: RentalParametersFormValues,
  disablePast?: boolean,
) => {
  if (!date) {
    return DATE_STATUS_DISABLED //disabled if data is empty
  }

  if (disablePast) {
    //is day before
    if (date.isBefore(now, 'day')) {
      return DATE_STATUS_PAST // past
    }
  }

  if (
    rentalParameter?.advanced_booking_options
      ?.months_in_advance_receive_reservations
  ) {
    const maxStartPossible = dayjs().add(
      rentalParameter.advanced_booking_options
        .months_in_advance_receive_reservations,
      'month',
    )
    if (date.isAfter(maxStartPossible, 'day')) {
      return DATE_STATUS_DISABLED // too much in future
    }
    //the night before
    if (date.isSame(maxStartPossible, 'day')) {
      return DATE_STATUS_ENABLED_AM // too much in future
    }
  }

  let filteredIcals: IcalType[] = []
  if (icals && icalsMap) {
    filteredIcals = getFilteredIcals(date, icals, icalsMap)
  }

  /*// if selected start
  if (
    selectedDates &&
    selectedDates[0] &&
    !selectedDates[1] &&
    date.isBefore(selectedDates[0], 'day')
  ) {
    return DATE_STATUS_PAST
  }

  if (blockPeriodCalendars && selectedDates) {
    for (const calendar of blockPeriodCalendars) {
      const calendarStart = dayjs(calendar.start)
      if (
        selectedDates &&
        selectedDates[0] &&
        !selectedDates[1] &&
        date.isAfter(calendarStart, 'day') &&
        (calendarStart.isAfter(selectedDates[0], 'day') ||
          calendarStart.isSame(selectedDates[0], 'day'))
      ) {
        return DATE_STATUS_PAST
      }
    }
  }

  if (icals && selectedDates) {
    for (const ical of icals) {
      const calendarStart = dayjs(ical.start)
      if (
        selectedDates &&
        selectedDates[0] &&
        !selectedDates[1] &&
        date.isAfter(calendarStart, 'day') &&
        (calendarStart.isAfter(selectedDates[0], 'day') ||
          calendarStart.isSame(selectedDates[0], 'day'))
      ) {
        return DATE_STATUS_PAST
      }
    }
  }

  if (bookingPeriods && selectedDates) {
    for (const period of bookingPeriods) {
      const calendarStart = dayjs(period.start)
      if (
        selectedDates &&
        selectedDates[0] &&
        !selectedDates[1] &&
        date.isAfter(calendarStart, 'day') &&
        (calendarStart.isAfter(selectedDates[0], 'day') ||
          calendarStart.isSame(selectedDates[0], 'day'))
      ) {
        return DATE_STATUS_PAST
      }
    }
  }*/

  if (blockPeriodCalendars || icals) {
    return checkCalendarsAndIcals(
      date,
      blockPeriodCalendars,
      filteredIcals,
      rentalParameter,
      bookingPeriods,
    )
  }

  if (bookingPeriods) {
    return checkPeriods(date, bookingPeriods, DATE_STATUS_ENABLED)
  }

  return DATE_STATUS_ENABLED // enabled
}

const checkCalendarsAndIcals = (
  day: Dayjs,
  calendars?: CalendarValues[],
  icals?: IcalType[],
  rentalParameter?: RentalParametersFormValues,
  periods?: BookingPeriodValues[],
  initialStatus?: DateStatusNumber,
): DateStatusNumber => {
  if (!calendars) {
    calendars = []
  }
  if (!icals) {
    icals = []
  }

  const allowSameDayDepartureArrival = rentalParameter?.advanced_booking_options
    ? rentalParameter.advanced_booking_options[
        'allow_same_day_departure/arrival'
      ]
    : null

  let result: DateStatusNumber = initialStatus ?? DATE_STATUS_ENABLED
  if (result === DATE_STATUS_DISABLED) {
    // once it's disabled no need to check further
    return result
  }

  for (const calendar of calendars) {
    const calendarStart = dayjs(calendar.start)
    const calendarEnd = dayjs(calendar.end)

    if (day.isBetween(calendarStart, calendarEnd)) {
      return DATE_STATUS_DISABLED
    }

    if (
      !allowSameDayDepartureArrival &&
      (day.isSame(calendarStart, 'day') || day.isSame(calendarEnd, 'day'))
    ) {
      return DATE_STATUS_DISABLED
    }

    if (
      allowSameDayDepartureArrival &&
      day.isSame(calendarStart, 'day') &&
      now.isSame(calendarStart, 'day')
    ) {
      return DATE_STATUS_DISABLED
    }

    if (allowSameDayDepartureArrival && day.isSame(calendarStart, 'day')) {
      result = checkCalendarsAndIcals(
        day,
        calendars.filter((otherCalendar) => otherCalendar.id !== calendar.id),
        icals,
        rentalParameter,
        periods,
        DATE_STATUS_ENABLED_AM,
      )
      continue
    }

    if (allowSameDayDepartureArrival && day.isSame(calendarEnd, 'day')) {
      result = checkCalendarsAndIcals(
        day,
        calendars.filter((otherCalendar) => otherCalendar.id !== calendar.id),
        icals,
        rentalParameter,
        periods,
        DATE_STATUS_ENABLED_PM,
      )
    }
  }

  if (result === DATE_STATUS_DISABLED) {
    // once it's disabled no need to check further
    return result
  }

  for (const ical of icals) {
    const icalStart = dayjs(ical.start)
    const icalEnd = dayjs(ical.end)

    if (day.isBetween(icalStart, icalEnd)) {
      return DATE_STATUS_DISABLED
    }

    if (
      !allowSameDayDepartureArrival &&
      (day.isSame(icalStart, 'day') || day.isSame(icalEnd, 'day'))
    ) {
      return DATE_STATUS_DISABLED
    }

    if (
      allowSameDayDepartureArrival &&
      day.isSame(icalStart, 'day') &&
      now.isSame(icalStart, 'day')
    ) {
      return DATE_STATUS_DISABLED
    }

    if (allowSameDayDepartureArrival && day.isSame(icalStart, 'day')) {
      result = checkCalendarsAndIcals(
        day,
        calendars,
        icals.filter((otherIcal) => otherIcal.id !== ical.id),
        rentalParameter,
        periods,
        DATE_STATUS_ENABLED_AM,
      )
      continue
    }

    if (allowSameDayDepartureArrival && day.isSame(icalEnd, 'day')) {
      result = checkCalendarsAndIcals(
        day,
        calendars,
        icals.filter((otherIcal) => otherIcal.id !== ical.id),
        rentalParameter,
        periods,
        DATE_STATUS_ENABLED_PM,
      )
    }

    //this will break loop
    if (result === DATE_STATUS_DISABLED) {
      // once it's disabled no need to check further
      return result
    }
  }

  if (result === DATE_STATUS_DISABLED) {
    // once it's disabled no need to check further
    return result
  }

  if (periods) {
    return checkPeriods(day, periods, result)
  }

  return result
}

const checkPeriods = (
  day: Dayjs,
  periods: BookingPeriodValues[],
  defaultStatus: DateStatusNumber = DATE_STATUS_ENABLED,
): DateStatusNumber => {
  let returnValue: DateStatusNumber = defaultStatus //1 enabled by default

  if (returnValue === DATE_STATUS_DISABLED) {
    // once it's disabled no need to check further
    return returnValue
  }

  for (const period of periods) {
    const periodStart = dayjs(period.start)
    const periodEnd = dayjs(period.end)
    const allowSameDayDepartureArrival =
      period.allow_same_day_departure_or_arrival

    if (allowSameDayDepartureArrival && day.isSame(periodStart, 'day')) {
      returnValue = checkPeriods(
        day,
        periods.filter((otherPeriod) => otherPeriod.id !== period.id),
        DATE_STATUS_ENABLED_AM,
      )
      continue
    }

    if (allowSameDayDepartureArrival && day.isSame(periodEnd, 'day')) {
      returnValue = checkPeriods(
        day,
        periods.filter((otherPeriod) => otherPeriod.id !== period.id),
        DATE_STATUS_ENABLED_PM,
      )
      continue
    }

    if (
      day.isBetween(periodStart, periodEnd) ||
      day.isSame(periodStart, 'day') ||
      day.isSame(periodEnd, 'day')
    ) {
      return DATE_STATUS_DISABLED // disabled
    }
  }

  return returnValue // default
}

export function getCustomPricing(
  calendar: CalendarValues | null,
  basicPricing?: BasicPricingFormValues | null,
): BasicPricingFormValues | CustomPricingValues | null {
  if (!calendar) {
    return basicPricing ? basicPricing : null
  }

  if (calendar.is_block_period) {
    return basicPricing ? basicPricing : null
  }

  const customPricing = calendar.custom_pricing || basicPricing

  return customPricing ? customPricing : null
}

export const getDaysBetweenDates = (start: Dayjs, end: Dayjs): Dayjs[] => {
  let days: Dayjs[] = []
  let currentDate: Dayjs = start

  if (end.isBefore(start)) {
    return days
  }

  while (currentDate.isSameOrBefore(end, 'day')) {
    days.push(currentDate)
    currentDate = currentDate.add(1, 'days')
  }

  return days
}

export const getDatesStatusMap = (
  visibleMonths: string[],
  blockPeriodCalendars?: CalendarValues[],
  notBlockPeriodCalendars?: CalendarValues[],
  icals?: IcalType[],
  icalsMap?: {[key: string]: number[]},
  bookingPeriods?: BookingPeriodValues[],
  property?: PropertyType,
) => {
  const basicPricing = property ? property.basic_pricing : null
  const currency = basicPricing?.currency
  const currencySymbol = getCurrencySymbol(currency)

  let arrivingTimeArr = property
    ? property.rental_parameter
      ? property.rental_parameter.arriving_time.split(':')
      : []
    : []
  let rentalParameterHour: number =
    arrivingTimeArr.length > 0 ? parseInt(arrivingTimeArr[0]) : 12 //default 12 h
  let rentalParameterMinute: number =
    arrivingTimeArr.length > 1 ? parseInt(arrivingTimeArr[1]) : 0 //default 0 min

  const newMap: DatesStatusMap = {
    // ...currentDatesStatusMap,
  }

  let daysToCheck: Dayjs[] = []
  for (let month of visibleMonths) {
    let startOfMonth = dayjs(month, 'YYYY-MM').startOf('month')
    let endOfMonth = dayjs(month, 'YYYY-MM').endOf('month')

    //a date that every month have, 15
    let middleDate = `${month}-15`
    if (typeof newMap[middleDate] !== 'undefined') {
      continue //this month was already calculated
    }

    //add the entire month
    daysToCheck = daysToCheck.concat(
      getDaysBetweenDates(startOfMonth, endOfMonth),
    )
  }

  let lastDayFormatted: string | null = null
  for (const day of daysToCheck) {
    //middle of the day check
    const dayWithTime = day
      .set('hour', rentalParameterHour)
      .set('minute', rentalParameterMinute)
      .set('second', 0)

    const calendarsInDay = getCalendarsForDate(
      dayWithTime,
      notBlockPeriodCalendars,
    )

    const dayOfWeek = dayWithTime.isoWeekday()
    const isWeekEnd = dayOfWeek === 6 || dayOfWeek === 5
    let customPricing: BasicPricingFormValues | CustomPricingValues | null =
      basicPricing

    calendarsInDay.forEach((calendar) => {
      if (calendar.custom_pricing) {
        customPricing = calendar.custom_pricing
      }
    })

    const price = getPrice(isWeekEnd, customPricing)
    const priceString = price ? `${currencySymbol}${price}` : ''
    const dayFormatted = day.format('YYYY-MM-DD')

    //todo should optimize getStatus even more but it's a good begining
    newMap[dayFormatted] = {
      date: dayWithTime,
      status: getDateStatus(
        dayWithTime,
        blockPeriodCalendars,
        icals,
        icalsMap,
        bookingPeriods,
        property ? property.rental_parameter : undefined,
        true,
      ),
      price: priceString,
    }

    //so continous date make more sense
    //clean up status from last day status
    //thats happen when ical of calendar start and end the same day or the next day
    if (lastDayFormatted) {
      //should not be enabled
      if (
        newMap[dayFormatted].status === DATE_STATUS_ENABLED_AM &&
        (newMap[lastDayFormatted].status === DATE_STATUS_ENABLED_AM ||
          newMap[lastDayFormatted].status === DATE_STATUS_DISABLED)
      ) {
        newMap[dayFormatted].status = DATE_STATUS_DISABLED
      }

      //should not be enabled
      if (
        (newMap[dayFormatted].status === DATE_STATUS_ENABLED_PM ||
          newMap[dayFormatted].status === DATE_STATUS_DISABLED) &&
        newMap[lastDayFormatted].status === DATE_STATUS_ENABLED_PM
      ) {
        newMap[lastDayFormatted].status = DATE_STATUS_DISABLED
      }
    }

    lastDayFormatted = dayFormatted
  }
  return newMap
}
